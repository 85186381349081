@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.form {
  margin-bottom: 2rem;
}

.userCellContent {
  padding-left: 32px;
  position: relative;
  &.blocked {
    text-decoration: line-through;
  }
  .avatar {
    position: absolute;
    left: 0;
    top: calc(50% + 1px);
    transform: translateY(-50%);
    height: 28px;
    width: 28px;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
      font-size: 0;
      min-height: 28px;
      min-width: 28px;
      object-fit: cover;
    }
  }
  .username {
    max-width: calc(100vw - 160px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
  }
}

.customerCell {
  width: 200px;
}

.versionCell {
  width: 80px;
}

.permissionsCell {
  width: 110px;
}

.subscriptionCell {
  width: 170px;
}

.actionsCell {
  min-width: 90px;
  width: 90px;
}
