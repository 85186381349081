@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.username {
  width: 300px;
  justify-content: left;
  @include mobile {
    width: 140px;
  }
  :global(.icon) {
    flex-shrink: 0;
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.score {
    width: 100px;
}