.modalBody {
  max-height: calc(100vh - 200px);
  overflow: auto;
  &::before,
  &::after {
    content: '';
    height: 1rem;
    display: block;
  }
}

.size-large {
  width: 1024px;
  max-width: 100%;
}
