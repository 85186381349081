@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.author,
.winner {
  width: 205px;
  max-width: 205px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include mobile {
    display: none;
  }
}

.date {
  width: 190px;
  white-space: nowrap;
  @include mobile {
    display: none;
  }
}
