@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.actions {
  min-width: 100px;
}

.question {
  & + .question {
    border-top: 1px solid $grey-lighter;
    padding-top: 3rem;
    margin-top: 3rem;
  }

  .questionText {
    margin-bottom: 1rem;
  }
}

.answersTable {
  margin-top: 2rem;
  td {
    word-break: break-word;
  }
  .statusCell {
    width: 100px;
  }
  .actionsCell {
    width: 100px;
  }
}

.media {
  width: 300px;
  max-width: 100%;
  margin: 1rem auto;
  &.image {
    background-color: $white;
  }
  & > * {
    width: 100%;
    vertical-align: top;
  }
}
