@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.wrapper {
  @include mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

.description {
  padding-bottom: 3rem;
  border-bottom: 1px solid $grey-lighter;
  margin-bottom: 3rem;
}

.results {
  margin-bottom: 6rem;
  .userCellContent {
    padding-left: calc(0.75rem + 32px);
    padding-right: calc(0.75rem + 24px);
    position: relative;
    .avatar {
      position: absolute;
      left: 0.75rem;
      top: calc(50% + 1px);
      transform: translateY(-50%);
      height: 28px;
      width: 28px;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        font-size: 0;
        min-height: 28px;
        min-width: 28px;
        object-fit: cover;
      }
    }
    .nationalRank {
      position: absolute;
      background-image: url('../../img/star.svg');
      color: $grey-dark;
      background-repeat: no-repeat;
      width: 24px;
      line-height: 28px;
      text-align: center;
      font-size: 8px;
      top: 4px;
      text-decoration: none;
    }
  }
}

.question {
  padding-bottom: 3rem;
  & + .question {
    border-top: 1px solid $grey-lighter;
    padding-top: 3rem;
  }

  .questionText {
    margin-bottom: 1rem;
  }

  .media {
    width: 300px;
    max-width: 100%;
    margin: 1rem auto;
    &.image {
      background-color: $white;
    }
    & > * {
      width: 100%;
      vertical-align: top;
    }
  }
}
