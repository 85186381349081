@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.question {
  & + .question {
    border-top: 1px solid $grey-lighter;
    padding-top: 3rem;
    margin-top: 3rem;
  }

  .media {
    width: 300px;
    max-width: 100%;
    margin: 1rem auto;
    &.image {
      background-color: $white;
    }
    & > * {
      width: 100%;
      vertical-align: top;
    }
  }

  .table {
    margin: 2rem 0;
    th,
    td {
      &:first-child,
      &:last-child {
        width: 40%;
      }
    }
  }
}
