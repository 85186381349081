@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.tableWrapper {
  position: relative;
  .tableContainer {
    padding-left: 310px;
    @include mobile {
      padding-left: 140px;
    }
    :global(table) {
      border-collapse: separate;
    }
    :global(th),
    :global(td) {
      height: 37px;
      white-space: nowrap;
    }

    .rankCell {
      position: absolute;
      left: 0;
      width: 25px;
      padding: 0.5em 0.25em !important;
      background-color: $white;
      @media (prefers-color-scheme: dark) {
        background-color: $black-bis;
      }
    }

    .changeCell {
      position: absolute;
      left: 25px;
      width: 25px;
      padding: 0.5em 0 !important;
      background-color: $white;
      @media (prefers-color-scheme: dark) {
        background-color: $black-bis;
      }
    }

    .userCell {
      position: absolute;
      left: 50px;
      width: 260px;
      background-color: $white;
      @media (prefers-color-scheme: dark) {
        background-color: $black-bis;
      }
      @include mobile {
        width: 90px;
      }
      .userCellContent {
        padding-left: 32px;
        position: relative;
        .avatar {
          position: absolute;
          left: 0;
          top: calc(50% + 1px);
          transform: translateY(-50%);
          height: 28px;
          width: 28px;
          font-size: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          img {
            font-size: 0;
            min-height: 28px;
            min-width: 28px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.top1 {
  background-color: #ff0000b3;
  color: $white;
}

.top2 {
  background-color: #ff7f00b3;
}

.top3 {
  background-color: #ffff00b3;
}

.top4 {
  background-color: #00ff00b3;
}

.top5 {
  background-color: #0000ffb3;
  color: $white;
}

.top6 {
  background-color: #4b0082b3;
  color: $white;
}

.top7 {
  background-color: #9400d3b3;
  color: $white;
}

.top8 {
  background-color: #dbdbdbb3;
}

.top9 {
  background-color: #4a4a4ab3;
  color: $white;
}

.top10 {
  background-color: #2f2f2fb3;
  color: $white;
}

.legend {
  dt {
    display: inline-block;
    width: 5rem;
    &::after {
      content: ':';
    }
  }
  dd {
    display: inline-block;
    margin-left: 0;
    @include mobile {
      display: block;
    }
  }
}

.scale {
  list-style: none !important;
  margin-left: 0 !important;
  li {
    position: relative;
    min-height: 2rem;
    padding-left: 3rem;
    display: flex;
    align-items: center;
  }
  .square {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
