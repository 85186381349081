@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.questions {
  .form {
    margin-bottom: 2rem;
  }
  .fields {
    flex-direction: column;
    @include widescreen {
      flex-direction: row;
    }
    .radios {
      line-height: 2.5rem;
      label {
        margin-right: 1rem;
      }
      input {
        position: relative;
        top: -1px;
        margin-right: 0.25rem;
      }
    }
    .control {
      &:last-child {
        margin-top: 1rem;
        @include widescreen {
          margin-top: 0;
        }
      }
    }
  }
  .label {
    line-height: 2.5rem;
    padding-right: 1rem;
    margin-bottom: 0;
  }
  .question {
    min-width: 600px;
    &.admin {
      min-width: 550px;
    }
  }
  .answer {
    width: 200px;
  }
  .quiz {
    min-width: 200px;
  }
  .action {
    text-align: center;
  }
}
