@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.question {
  .media {
    width: 300px;
    max-width: 100%;
    margin: 1rem auto;
    &.image {
      background-color: $white;
    }
    & > * {
      width: 100%;
      vertical-align: top;
    }
  }

  dl {
    margin-top: 2rem;
    dt {
      font-weight: bold;
      display: inline-block;
      &::after {
        content: ':';
      }
    }
    dd {
      display: inline-block;
      margin-left: 0.2rem;
    }
  }

  .table {
    margin: 2rem 0;
  }
}
