@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.question {
  padding-bottom: 3rem;
  & + .question {
    border-top: 1px solid $grey-lighter;
    padding-top: 3rem;
  }

  .questionText {
    margin-bottom: 1rem;
  }

  .media {
    width: 300px;
    max-width: 100%;
    margin: 1rem auto;
    &.image {
      background-color: $white;
    }
    & > * {
      width: 100%;
      vertical-align: top;
    }
  }
}

.statistics {
  .opponentTitle {
    margin-bottom: 0;
  }
  .opponentName {
    margin-bottom: 1rem;
  }
  .missing {
    line-height: 0.9em;
    display: block;
    margin-top: 0.5em;
  }
  .assignPoints{
    margin-bottom: 1rem;
  }
  .genresTable {
    .total {
      width: 65px;
    }
    .percentage {
      width: 65px;
    }
  }
}
