.react-datepicker-popper {
  z-index: 5;
}

.field {
  .react-datepicker-wrapper {
    height: 2.5em;
    border-radius: 4px;
    background-color: $white;
    border: 1px solid $grey-lighter;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    display: inline-flex;
    overflow: hidden;
    @media (prefers-color-scheme: dark) {
      background-color: $black-ter;
      border-color: $grey-light;
    }
    &:hover {
      border-color: $grey-light;
    }
    .react-datepicker__input-container {
      display: flex;
    }
    input {
      background-color: transparent;
      border: none;
      padding-left: calc(0.625em - 1px);
      padding-right: calc(0.625em - 1px);
      @media (prefers-color-scheme: dark) {
        color: $white-bis;
      }
      &[disabled] {
        background-color: $white-ter;
        cursor: not-allowed;
        @media (prefers-color-scheme: dark) {
          background-color: $grey;
        }
      }
    }
  }
  .has-icons-left {
    .react-datepicker-wrapper {
      input {
        font-size: 14px;
        padding-left: 2.5em;
      }
    }
  }
}

.react-datepicker {
  border-radius: 4px;
  font-family: inherit;
}

.react-datepicker__header {
  @media (prefers-color-scheme: dark) {
    background-color: $black-ter;
  }
  .custom-year-select {
    border: 1px solid $grey-light;
    height: 2rem;
    padding: 0 0.2rem;
    @media (prefers-color-scheme: dark) {
      background-color: $black-ter;
      color: $white;
    }
  }
  .custom-month-container {
    position: relative;
    padding: 7px 0;
  }
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  @media (prefers-color-scheme: dark) {
    color: $white;
  }
  &::first-letter {
    text-transform: uppercase;
  }
}

.react-datepicker__month-container {
  @media (prefers-color-scheme: dark) {
    background-color: $black-bis;
  }
}

.react-datepicker__day {
  @media (prefers-color-scheme: dark) {
    color: $white;
    &:hover {
      background-color: $grey-darker;
    }
  }
}

.react-datepicker__day--disabled {
  @media (prefers-color-scheme: dark) {
    color: $grey-darker;
  }
}

.react-datepicker__day--outside-month {
  opacity: 0;
  pointer-events: none;
}

.react-datepicker__time-container {
  .react-datepicker__header--time {
    display: none;
  }
  .react-datepicker__time {
    @media (prefers-color-scheme: dark) {
      background-color: $black-bis;
      color: $white;
    }
    .react-datepicker__time-box {
      ul.react-datepicker__time-list {
        li.react-datepicker__time-list-item {
          height: auto;
          line-height: 1;
          padding: 0.5em 1em;
          margin: 0;
          &:hover {
            @media (prefers-color-scheme: dark) {
              background-color: $grey-darker;
            }
          }
        }
      }
    }
  }
}
