@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

.questions {
  .form {
    margin-bottom: 2rem;
  }
  .fields {
    flex-direction: column;
    @include widescreen {
      flex-direction: row;
      align-items: center;
    }
    .radios {
      line-height: 2.5rem;
      label {
        margin-right: 1rem;
      }
      input {
        position: relative;
        top: -1px;
        margin-right: 0.25rem;
      }
    }
    .control {
      &:last-child {
        margin-top: 1rem;
        @include widescreen {
          margin-top: 0;
        }
      }
    }
    .onlyNonUsed {
      margin-top: 1rem;
      margin-bottom: 1rem;
      @include widescreen {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }
  .label {
    line-height: 2.5rem;
    padding-right: 1rem;
    margin-bottom: 0;
  }
  .question {
    min-width: 600px;
    .used {
      text-decoration: line-through;
    }
  }
  .answer {
    width: 200px;
    .used {
      text-decoration: line-through;
    }
  }
  .origin {
    width: 200px;
  }
  th.actions,
  .media {
    text-align: center;
  }
  td.media {
    line-height: 35px;
  }
  td.actions {
    min-width: 125px;
  }
  .buttons {
    justify-content: center;
  }
}

.mediaContainer {
  width: 300px;
  max-width: 100%;
  margin: 1rem auto;
  &.image {
    background-color: $white;
  }
  & > * {
    width: 100%;
    vertical-align: top;
  }
}
