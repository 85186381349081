.info {
  display: flex;
  align-items: center;
  flex-direction: column;
  .avatarWrapper {
    display: inline-block;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .avatar {
      font-size: 0;
      min-height: 100px;
      min-width: 100px;
      object-fit: cover;
    }
  }
  .icon {
    font-size: 100px;
  }
  .emailWrapper {
    padding-top: 1rem;
  }
}

.chart {
  margin-bottom: 3rem;
  .chartLegend {
    list-style: none;
    text-align: center;
    margin: 2rem 0 0;
    li {
      display: block;
      text-align: center;
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border: 2px solid;
        margin-right: 5px;
      }
      &.user1 {
        &:before {
          background-color: hsla(204, 86%, 53%, 0.3);
          border-color: hsl(204, 86%, 53%);
        }
      }
      &.user2 {
        &:before {
          background-color: hsla(171, 100%, 41%, 0.3);
          border-color: hsl(171, 100%, 41%);
        }
      }
    }
  }
}

.compare {
  margin-bottom: 3rem;
}

.genresTable {
  .subgenre {
    th {
      padding-left: 1.5rem;
    }
  }
}

.compareTables {
  th,
  td {
    white-space: nowrap;
  }
}
