@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/derived-variables.sass';

.burgerWrapper {
  margin-left: auto;
}

.burger {
  background-color: transparent;
  border: none;
}

.impersonating {
  box-shadow: 0 0 20px $danger !important;
}
